import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import ReactTable from "react-table";
import Loader from "react-loader-spinner";

import { getPatients } from "../actions/index";

import deviceImage from "../assets/img/NEDevice.png";

import CheckboxOn from "@material-ui/icons/Done";
import CheckboxOff from "@material-ui/icons/Remove";
import Tooltip from "material-ui/Tooltip";

import moment from "moment";

import ReactMinimalPieChart from "react-minimal-pie-chart";

const today = moment().format("YYYY-MM-DD");

class ComplianceTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }

    this.renderPatientCompliance = this.renderPatientCompliance.bind(this);
    this.Schedule = this.Schedule.bind(this);
    this.Compliance = this.Compliance.bind(this);
  }

  componentDidMount() {
    this.setState({loading: false});
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextProps.patients.loadingOneByOne && nextProps.patients.fullData) {
      return true;
    }

    if (nextProps.patients.loadingOneByOne && this.state.loading) {
      this.setState({loading: false});
      return true;
    }
    
    let isPatientWithDeviceFullData = false;
    let countNoDevice = 0;
    for (let i = 0; i < nextProps.patients.data.length; i++) {
      if (nextProps.patients.data[i].devices !== null) {
        if (!isPatientWithDeviceFullData && nextProps.patients.data[i].fullData) {
            isPatientWithDeviceFullData = true
        }
      } else {
        if (nextProps.patients.data[i].fullData) {
          countNoDevice++;
        }
      }
    }
    if (isPatientWithDeviceFullData && countNoDevice === 0) {
      return true;
    } else if (countNoDevice > 0 && (countNoDevice % 100) === 0) {
      return true;
    }
    return false;
  }

  Schedule({patientData}) {
    let element;

    patientData.fullData ? (
    patientData.treatments &&
    patientData.treatments.length > 0 &&      
    patientData.treatments[0].start_date ? (
      patientData.treatments[0].end_date < today ? (
        element =  <div key={2} sort={patientData.treatments[0].end_date}>
            Ended: {patientData.treatments[0].end_date}
          </div>
        ) : patientData.treatments[0].start_date > today ? (
          element = <div key={4} sort={patientData.treatments[0].start_date}>
            Starts: {patientData.treatments[0].start_date}
          </div>
        ) : (
          element = <div key={3} sort={patientData.treatments[0].end_date}>
            Started: {patientData.treatments[0].start_date}
            <br />
            Ends: {patientData.treatments[0].end_date}
          </div>
        )
      ) : (
        element = <div key={1}>No treatment scheduled</div>
      ) 
      ) : (
        element = <Loader type="Oval" color="#4caf50" height="35" width="35" style={{ margin: "3%" }}/>
        )

    return <Suspense fallback={<div>Loading...</div>}>
        {element}
      </Suspense>
  };

  Compliance({patientData, sessionsToRender, futureSessions, beforeSessions}) {
    let element;

    patientData.fullData ? (
    patientData.treatments &&
    patientData.treatments.length > 0 &&
    patientData.treatments[0].time_compliance ? (
      element = <div
        key={
          1.0 -
          patientData.treatments[0].time_compliance[1] /
            (patientData.treatments[0].time_compliance[0] +
              patientData.treatments[0].time_compliance[1] +
              patientData.treatments[0].time_compliance[2])
        }
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: 10,
          marginBottom: 10,
          width: "90%"
        }}
      >
          {sessionsToRender.map((item, i) => {
              // ITEM TIME_COMPLIANCE
              let executed = Math.round(item.time_compliance[0])
              let duration = Math.round(
                item.time_compliance[0] +
                  item.time_compliance[1] +
                  item.time_compliance[2]
              )

              // EVALUATE STATUS OF SESSION
              let color
              if (item.session_date > today) color = "default"
              else if (item.session_date === today && executed === 0)
                color = "default"
              else {
                if (executed >= duration - 1) color = "green"
                else if (executed >= 1) color = "orange"
                else color = "red"
              }

              // TOOLTIP TEXT
              if (item.session_date > today) {
                var tooltipTitle = ` Protocol: ${item.sessionprotocol.name} Day: ${item.session_date} `
              } else {
                var tooltipTitle = ` Protocol: ${
                  item.sessionprotocol.name
                } Day:
                  ${item.session_date}
                  Sucessfull: ${Math.round(
                    (item.time_compliance[0] / duration) * 100
                  )}% Failed: ${Math.round(
                  (item.time_compliance[1] / duration) * 100
                )}% `
              }

              // PIE SETUP
              const data =
                color === "green"
                  ? [
                      {
                        color: "#4caf50",
                        value: item.time_compliance[0]
                      },
                      {
                        color: "#f44336",
                        value: item.time_compliance[1]
                      },
                      {
                        color: "#ff9800",
                        value: item.time_compliance[2]
                      }
                    ]
                  : color === "default"
                  ? [
                      {
                        color: "#bfbfbf",
                        value: 33
                      },
                      {
                        color: "#bfbfbf",
                        value: 0
                      },
                      {
                        color: "#bfbfbf",
                        value: 0
                      }
                    ]
                  : [
                      {
                        color: "#ff9800",
                        value: item.time_compliance[0]
                      },
                      {
                        color: "#f44336",
                        value: item.time_compliance[1]
                      },
                      {
                        color: "#ff9800",
                        value: item.time_compliance[2]
                      }
                    ]

              return (
                <>
                  {futureSessions > 0 && i === 0 && (<div key={`${i}-futureSessions`} style={{ position: "relative", width: "70px", marginRight: "8%" }}>
                    + {futureSessions} scheduled</div>)}
                  <div key={i} style={{ margin: "2%", width: "35px", display: "flex", alignItems: "center" }}>
                    <div>
                      <Tooltip
                        title={tooltipTitle}
                        placement="right-end"
                        key={item.sessionprotocol.name}
                      >
                        <CheckboxOff
                          style={{
                            position: "absolute",
                            opacity: 0,
                            height: "45px",
                            width: "45px"
                          }}
                        />
                      </Tooltip>
                      <ReactMinimalPieChart
                        animate={false}
                        animationDuration={500}
                        animationEasing="ease-out"
                        background="#bfbfbf"
                        cx={50}
                        cy={50}
                        data={data}
                        label={false}
                        labelPosition={50}
                        lengthAngle={360}
                        lineWidth={100}
                        onClick={undefined}
                        onMouseOut={undefined}
                        onMouseOver={undefined}
                        paddingAngle={0}
                        radius={50}
                        rounded={false}
                        startAngle={0}
                        style={{
                          height: "35px"
                        }}
                        viewBoxSize={[100, 100]}
                      />
                    </div>
                    {beforeSessions > 0 && i === sessionsToRender.length - 1 && (<div key={`${i}-beforeSessions`} style={{ marginLeft: "50px", width: "70px", position: "absolute" }}>+ {beforeSessions} before</div>)}
                  </div>
                  
                </>
              )
          })}
      </div>
    ) : (
      element = <div key={-1.0} />      
    )
    ) : (
      element = <Loader type="Oval" color="#4caf50" height="35" width="35" style={{ margin: "3%" }}/>
      )

    return <Suspense fallback={<div>Loading...</div>}>
        {element}
      </Suspense>
  };

  renderPatientCompliance(patientData) {
    console.log("Rendering patient compliance...", patientData);

    let futureSessions = 0;
    let futureSessionsToRender = [];
    let todaySessions = 0;
    let sessionsToRender = [];
    let beforeSessions = 0;
    
    if (patientData.treatments && patientData.treatments.length && patientData.treatments[0].time_compliance) {
        const sortedSessions = patientData.treatments[0].sessions.sort((a, b) => new Date(b.session_date) - new Date(a.session_date));
        sortedSessions.forEach((item, i) => {
          if (item.time_compliance) {
            if (item.session_date > today) {
              futureSessions = futureSessions + 1;            
              futureSessionsToRender.push(item);
            } else if (item.session_date === today) {
              todaySessions = todaySessions + 1;            
              sessionsToRender.push(item);
            } else {
              sessionsToRender.push(item);
            }
          }
        })
      }

    if (todaySessions === 0 && futureSessions > 0 ) {
      const firstSession = futureSessionsToRender.pop();
      sessionsToRender.unshift(firstSession);
      futureSessions = futureSessions - 1;
    }

    if (futureSessions > 0 && !sessionsToRender.length || sessionsToRender.length < 18) {
      sessionsToRender = futureSessionsToRender.concat(sessionsToRender);
      if (sessionsToRender.length <= 20) {
        futureSessions = 0;
      }      
      if (sessionsToRender.length > 20) {
        futureSessions = sessionsToRender.length - 18;
        sessionsToRender = sessionsToRender.slice(sessionsToRender.length - 18);
      }
    } else if (futureSessions > 0 && sessionsToRender.length && sessionsToRender.length >= 18) {
      beforeSessions = sessionsToRender.length - 16;
      sessionsToRender = sessionsToRender.slice(0, 16);
    }

    if (futureSessions === 0 && sessionsToRender.length && sessionsToRender.length > 20) {
      beforeSessions = sessionsToRender.length - 18;
      sessionsToRender = sessionsToRender.slice(0, 18);
    } 

    return {
      code: (
        <NavLink to={"/studies/subjects/" + patientData.code} key={patientData.code}>
          {patientData.code}
        </NavLink>
      ),

      lang: patientData.lang && Object.keys(this.props.languages.byId).length > 0  
        ? this.props.languages.byId[patientData.lang].name
        : "-",

      isdevice:
        patientData.devices && patientData.devices.length > 0 ? (
          <Tooltip
            title={patientData.devices[0].mac}
            placement="left-end"
            key={patientData.devices[0].mac}
          >
            <CheckboxOn />
          </Tooltip>
        ) : (
          <CheckboxOff key="ZZZ" />
      ),        
      
      schedule:
        (<this.Schedule patientData={patientData}/>),

      compliance:
        (<this.Compliance patientData={patientData} sessionsToRender={sessionsToRender} futureSessions={futureSessions} beforeSessions={beforeSessions}/>)
    }
  };

  render() {
    return (
      <ReactTable
        data={
          this.props.patients.searched && this.props.patients.searched.length > 0 ? this.props.patients.searched.map(this.renderPatientCompliance) :
          this.props.patients.data.map(this.renderPatientCompliance).slice().reverse()
        }
        columns={[
          {
            Header: "Subject Code",
            accessor: "code",
            sortMethod: (a, b) => {
              if (a.key.toLowerCase() > b.key.toLowerCase()) {
                return 1
              } else {
                return -1
              }
            }
          },          
          {
            Header: "Language",
            accessor: "lang",
            maxWidth: 160
          },
          {
            Header: (
              <img
                src={deviceImage}
                height={"30px"}
                alt={"Is Device Assigned?"}
              />
            ),
            accessor: "isdevice",
            width: 80,
            sortMethod: (a, b) => {
              if (a.key > b.key) return 1
              else return -1
            }
          },
          {
            Header: "Treatment Schedule",
            accessor: "schedule",
            sortMethod: (a, b) => {
              if (a.key > b.key) return 2
              else if (a.key === b.key) {
                if (a.props.sort > b.props.sort) return 1
                else return -1
              } else return -2
            }
          },
          {
            Header: "Treatment Compliance (most recent first)",
            accessor: "compliance",
            minWidth: 180,
            sortMethod: (a, b) => {
              if (a.key > b.key) {
                return 1
              } else {
                return -1
              }
            },
            headerStyle: { textAlign: "left" }
          }
        ]}
        defaultSorted={[
          {
            id: "isdevice"
          }
        ]}
        defaultPageSize={100}
        pageSize={100}
        minRows={6}
        showPaginationBottom={false}
        showPageSizeOptions={false}
        className="-highlight"
        noDataText="There are no patients defined in your organization"
        loading={this.state.loading}        
      />
    )
  }
}

function mapStateToProps({ patients, languages }) {
  console.log("Received patients", patients, languages)  
  return { patients, languages }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getPatients }, // Not in use
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceTable);
