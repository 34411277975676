import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector } from 'react-redux';

import Log from '../../logging/Log.jsx';

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import AppBar from "material-ui/AppBar";
import Toolbar from "material-ui/Toolbar";
import IconButton from "material-ui/IconButton";
import Button from "material-ui/Button";
import Hidden from "material-ui/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// core components
import HeaderLinks from "./HeaderLinks";
import CustomIconButton from "components/CustomButtons/IconButton.jsx";
import AccessLevelButton from "../../molecules/AccessLevelButton";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

const pathToRegex = (path) => {
  let pathParts = path.split("/")
  pathParts.forEach((element, index, _array) => {
    if(element.length > 0 && element[0] === ":") {
      _array[index] = ".+"
    }
  });
  return "^" + pathParts.join("\\/") + "$";
}

function Header({ ...props }) {
  Log.info("Rendering header with:", props)
  function makeBrand() {
    var name;
    props.routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if(props.location.pathname.match(pathToRegex(prop.path))) {
        name = prop.name;
      }
      return null;
    });
    return name;
  }
  const { classes, color, rtlActive } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });

  const accessLevel = useSelector(state => state.authenticationState.accessLevel);
  let message;
  accessLevel === "blinded" ? message = "BLINDED MODE" : message = "ADMIN MODE";

  const makeBreadcrumb = () => {
    let breadcrumb;
    if (props.location.pathname === "/studies") {
      return breadcrumb = "#";
    }
    return breadcrumb = props.routes[2].path;
  } 
  
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <CustomIconButton color="white" onClick={props.sidebarMinimize}>
                <ViewList className={classes.sidebarMiniIcon} />
              </CustomIconButton>
            ) : (
              <CustomIconButton color="white" onClick={props.sidebarMinimize}>
                <MoreVert className={classes.sidebarMiniIcon} />
              </CustomIconButton>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <NavLink to={makeBreadcrumb()} style={{color: "inherit"}}>
            <Button  className={classes.title}>
              <Hidden smDown>{makeBrand()}</Hidden>            
              <Hidden mdUp>{`${makeBrand()} - Version 1.4.7`}</Hidden>
            </Button> 
          </NavLink>
          <AccessLevelButton message={message}/>          
        </div>
        <Hidden smDown><p>Version 1.4.7</p></Hidden>        
        <Hidden smDown implementation="css">
          <HeaderLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp>        
          <IconButton
            className={classes.appResponsive}
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
};

export default withStyles(headerStyle)(Header);
